<nz-modal [(nzVisible)]="isVisiblePopUpForgotPassWord" nzCentered [nzContent]="modalContent" [nzFooter]="modalFooter"
    (nzOnCancel)="handleCancel()">
    <!-- <ng-template #modalTitle>
        <div class="text-center text-primary font-semibold text-[40px]">
            <span>{{'Menu.unit'|translate}}</span>
        </div>
    </ng-template> -->

    <ng-template #modalContent>
        <div class="text-center text-primary font-semibold text-[40px] uppercase">
            <span>{{'PopUpForgotPassword.forgotPassword'|translate}}</span>
        </div>

        <div class="flex flex-col gap-2   h-fit lg:px-[30px] lg:pb-2 overflow-auto">
            <span>{{'PopUpForgotPassword.reciveOTP'|translate}}</span>
            <form [formGroup]="form">
                <nz-radio-group formControlName="reciveOTP" class="flex flex-col">
                    <label nz-radio nzValue="0">{{'PopUpForgotPassword.phoneNumber'|translate}}</label>
                    <label nz-radio nzValue="1">Email</label>
                </nz-radio-group>
                <span
                    *ngIf="form.get('reciveOTP')?.errors?.['required']&& (form.get('reciveOTP')?.dirty || form.get('reciveOTP')?.touched)"
                    class="text-red-600">
                    {{'PopUpRegister.noEmty'|translate}}
                </span>
            </form>
        </div>
    </ng-template>

    <ng-template #modalFooter>
        <div class="flex justify-center">
            <button nz-button nzType="primary"
                class="!bg-primary text-white border-none  rounded-lg hover:opacity-60 hover:bg-primary  hover:text-white"
                (click)="handleOk()" [nzLoading]="isConfirmLoading">{{'Button.confirm'| translate }}</button>
        </div>
    </ng-template>
</nz-modal>