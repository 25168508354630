<nz-modal [(nzVisible)]="isVisiblePopUpRegister" nzCentered [nzContent]="modalContent" [nzFooter]="modalFooter"
    (nzOnCancel)="handleCancel()">
    <!-- <ng-template #modalTitle>
        <div class="text-center text-primary font-semibold text-[40px]">
            <span>{{'Menu.unit'|translate}}</span>
        </div>
    </ng-template> -->

    <ng-template #modalContent>
        <div class="text-center text-primary font-semibold text-[40px]">
            <span>{{'PopUpRegister.register'|translate}}</span>
        </div>
        <form [formGroup]="form" class="grid grid-cols-2 gap-2 lg:!w-[450px]  h-fit lg:px-[30px] lg:pb-2 overflow-auto">
            <div class="col-span-2 flex flex-col">
                <span>{{'PopUpRegister.username'|translate}}<span class="text-red-500">*</span></span>
                <mat-form-field class="custom-input col-span-2" appearance="outline">
                    <input matInput formControlName="username" />
                    <mat-error
                        *ngIf="form.get('username')?.errors?.['required']&& (form.get('username')?.dirty || form.get('username')?.touched)"
                        class="text-red-600">
                        {{'PopUpRegister.noEmty'|translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-span-2 flex flex-col">
                <span>{{'PopUpRegister.fullName'|translate}}<span class="text-red-500">*</span></span>
                <mat-form-field class="custom-input col-span-2" appearance="outline">
                    <input matInput formControlName="fullName" />
                    <mat-error
                        *ngIf="form.get('fullName')?.errors?.['required']&& (form.get('fullName')?.dirty || form.get('fullName')?.touched)"
                        class="text-red-600">
                        {{'PopUpRegister.noEmty'|translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-span-2 flex flex-col">
                <span>{{'PopUpRegister.phoneNumber'|translate}}<span
                        class="text-red-500 text-[8px] cursor-pointer translate-y-[-5px] px-1 ml-1 inline-block border border-red-500"
                        (click)="showAlerPhoneNumber()">i</span></span>
                <mat-form-field class="custom-input col-span-2" appearance="outline">
                    <input matInput formControlName="phoneNumber" maxlength="10" />

                    <!-- <mat-error
                        *ngIf="form.get('phoneNumber')?.errors?.['required']&& (form.get('phoneNumber')?.dirty || form.get('phoneNumber')?.touched)"
                        class="text-red-600">
                        {{'PopUpRegister.noEmty'|translate}}
                    </mat-error> -->
                    <mat-error *ngIf="form.get('phoneNumber')?.errors?.['phoneNumberCheck']" class="text-red-600">
                        {{'PopUpRegister.wrongPhoneNumber'|translate}}
                    </mat-error>

                </mat-form-field>
            </div>
            <div class="col-span-2 flex flex-col">
                <span>Email<span
                        class="text-red-500 text-[8px] cursor-pointer translate-y-[-5px] px-1 ml-1 inline-block border border-red-500"
                        (click)="showAlerEmail()">i</span></span>
                <div class="flex relative">
                    <mat-form-field class="custom-input col-span-2  w-full" appearance="outline">
                        <input class="!w-[85%]" matInput formControlName="email" />
                        <mat-error
                            *ngIf="form.get('email')?.errors?.['email']&& (form.get('email')?.dirty || form.get('email')?.touched)"
                            class="text-red-600">
                            {{'PopUpRegister.wrongEmail'|translate}}
                        </mat-error>
                    </mat-form-field>
                    <span class="absolute top-[36%] right-2 translate-y-[-50%] hover:opacity-70 cursor-pointer"
                        (click)="handleConfirmEmail()">Xác nhận</span>
                </div>
            </div>

            <div class="col-span-2 flex flex-col">
                <span>{{'PopUpRegister.password'|translate}}<span class="text-red-500">*</span></span>
                <mat-form-field class="custom-input col-span-2 relative" appearance="outline">
                    <input matInput formControlName="password" (change)="updateValidateRepass($event)"
                        id="inputPassRegister" type="password" autocomplete="off" />
                    <i *ngIf="hidePass" class="fas fa-eye absolute right-0 top-[50%] translate-y-[-50%]"
                        (click)="showPass($event)"></i>
                    <i *ngIf="!hidePass" class="fa-solid fa-eye-slash absolute right-0 top-[50%] translate-y-[-50%]"
                        (click)="showPass($event)"></i>
                    <mat-error
                        *ngIf="form.get('password')?.errors?.['required']&& (form.get('password')?.dirty || form.get('password')?.touched)"
                        class="text-red-600">
                        {{'PopUpRegister.noEmty'|translate}}
                    </mat-error>
                    <mat-error *ngIf="form.get('password')?.errors?.['passWordCheck']" class="text-red-600">
                        {{'PopUpRegister.requiredPassword'|translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-span-2 flex flex-col mt-2">
                <span>{{'PopUpRegister.rePass'|translate}}<span class="text-red-500">*</span></span>
                <mat-form-field class="custom-input col-span-2" appearance="outline">
                    <input matInput formControlName="rePass" id="inputRePassRegister" type="password"
                        autocomplete="off" />
                    <i *ngIf="hideRePass" class="fas fa-eye absolute right-0 top-[50%] translate-y-[-50%]"
                        (click)="showRePass($event)"></i>
                    <i *ngIf="!hideRePass" class="fa-solid fa-eye-slash absolute right-0 top-[50%] translate-y-[-50%]"
                        (click)="showRePass($event)"></i>
                    <mat-error
                        *ngIf="form.get('rePass')?.errors?.['required']&& (form.get('rePass')?.dirty || form.get('rePass')?.touched)"
                        class="text-red-600">
                        {{'PopUpRegister.noEmty'|translate}}
                    </mat-error>
                    <mat-error *ngIf="form.get('rePass')?.errors?.['rePassCheck']" class="text-red-600">
                        {{'PopUpRegister.notMatch'|translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </ng-template>

    <ng-template #modalFooter>
        <div class="flex justify-center">
            <button nz-button nzType="primary"
                class="!bg-primary text-white border-none  rounded-lg hover:opacity-60 hover:bg-primary  hover:text-white"
                (click)="register()" [nzLoading]="isConfirmLoading">{{'Button.register'| translate }}</button>
        </div>
    </ng-template>
</nz-modal>